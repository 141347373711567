import React, { FC, useState } from 'react';
import { ChangeMemberPasswordForm, ChangeMemberPasswordProps } from './types';
import { useAppDispatch } from '../../../../../app/hooks/store';
import DefaultModal from '../../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import ContentCard from '../../../../../components/ContentCard';
import styles from './ChangeMemberPassword.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Button } from '@consta/uikit/Button';
import { changePasswordAction } from '../../../../../state/organization/actions';
import { useTranslation } from 'react-i18next';

const initialForm: ChangeMemberPasswordForm = { pass1: '', pass2: '' };

/**
 * Изменение пароля пользователя
 */
const ChangeMemberPassword: FC<ChangeMemberPasswordProps> = ({
  member,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [formError, setFormError] = useState<string | null>(null);

  const fullName = `${member?.surname} ${member?.name} ${member?.patronymic || ''}`;

  // Обработчики
  const handleChange =
    (key: keyof ChangeMemberPasswordForm) =>
    ({ value }: { value: string | null }) => {
      setFormError(null);
      setForm((prevState) => ({ ...prevState, [key]: value }));
    };

  const handeCancelClick = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (form.pass1 !== form.pass2) {
      setFormError(t('organization.changeMemberPassword.err.notMatch.text'));

      return;
    }

    if (!(form.pass1 && form.pass2)) {
      setFormError(t('organization.changeMemberPassword.err.required.text'));

      return;
    }

    setIsLoading(true);

    member?.uuid &&
      dispatch(
        changePasswordAction({ password: form.pass1, organizationId, userId: member?.uuid }),
      ).then(() => {
        setIsLoading(false);
        onClose();
      });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        loading={isLoading}
        label={t('organization.changeMemberPassword.cancel.button')}
        view="secondary"
        onClick={handeCancelClick}
      />
      <Button
        size="l"
        loading={isLoading}
        label={t('organization.changeMemberPassword.save.button')}
        onClick={handleSubmit}
      />
    </>
  );

  const renderInfo = () => (
    <ContentCard className={styles.memberInfo}>
      <Text size="m">{fullName}</Text>
      <Text size="m" view="secondary">
        {member?.email}
      </Text>
    </ContentCard>
  );

  const renderForm = () => (
    <>
      <TextField
        width="full"
        size="m"
        type="password"
        placeholder={t('organization.changeMemberPassword.pass1.input.placeholder')}
        label={t('organization.changeMemberPassword.pass1.input.label')}
        value={form.pass1}
        status={formError ? 'alert' : undefined}
        caption={formError ? formError : undefined}
        onChange={handleChange('pass1')}
      />
      <TextField
        width="full"
        size="m"
        type="password"
        placeholder={t('organization.changeMemberPassword.pass2.input.placeholder')}
        label={t('organization.changeMemberPassword.pass2.input.label')}
        value={form.pass2}
        status={formError ? 'alert' : undefined}
        caption={formError ? formError : undefined}
        onChange={handleChange('pass2')}
      />
    </>
  );

  return (
    <DefaultModal
      className={styles.ChangeMemberPassword}
      withHeader
      modalTitle={t('organization.changeMemberPassword.modal.title')}
      renderActions={renderActions}
      onClose={onClose}
    >
      <VerticalContainer space="s" isAutoWidth>
        {renderInfo()}
        {renderForm()}
      </VerticalContainer>
    </DefaultModal>
  );
};

export default ChangeMemberPassword;
