import React, { FC, useMemo, useState } from 'react';
import ClassicTable from '../../../components/ClassicTable';
import HorizontalContainer from '../../../components/HorizontalContainer';
import VerticalContainer from '../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './PromoCodeList.module.scss';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { DatePicker } from '@consta/uikit/DatePicker';
import { Slider } from '@consta/uikit/Slider';
import { useAppDispatch } from '../../../app/hooks/store';
import {
  getPromoCodeListAction,
  switchSelectPromoCodeAction,
} from '../../../state/promoCode/actions';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import {
  PromoCodeListFiltersDTO,
  PromoCodeListItemRes,
  TypeDiscountEnum,
} from '../../../types/serverInterface/promoCodeDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useNavigate } from 'react-router-dom';
import {
  activePromoCodeFiltersList,
  discountTypePromoCodeFiltersList,
  selectedPromoCodeFiltersList,
} from './consts';
import { useTranslation } from 'react-i18next';
import { DiscountTypePromoCodeFilterEnum } from './types';
import classNames from 'classnames';
import { IconFavoriteFill } from '../../../assets/icon/iconFavoriteFill';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconFavoriteOutLine } from '../../../assets/icon/iconFavoriteOutLine';
import { useExportPromoCode } from './hooks';
import TableHeaderButton from '../../../components/ClassicTable/ClassicTableHeader/TableHeaderButton';
import { IconDocFilled } from '../../../assets/icon/iconDocFilled';
import { IconCopy } from '@consta/uikit/IconCopy';
import { SortDirection } from '../../../types/enums/sortDirection';
import { transformFiltersDtoToReq, usePromoCodeList } from './usePromoCodeList';
import { IconQR } from '../../../assets/icon/iconQR';

/**
 * Список промокодов
 */
const PromoCodeList: FC = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const organizationId = getOrganizationId();

  const navigate = useNavigate();

  const {
    isLoading,
    promoCodeList,
    filters,
    fullLength,
    limit,
    page,
    debouncedFilters,
    setFilters,
    setIsNoDebounceChange,
  } = usePromoCodeList(organizationId);

  const [searchQuery, setSearchQuery] = useState('');

  const {
    handleExportFromClickBoardClick,
    handleExcelPromoCodeExportClick,
    handleExportFromPrint,
  } = useExportPromoCode({
    filters: transformFiltersDtoToReq(filters),
    organizationId: organizationId || 0,
    pagination: { limit: fullLength, page: 0, qty: fullLength },
    t,
  });

  const filterPromoCodeList = useMemo(() => {
    return promoCodeList
      ? promoCodeList?.filter(({ code }) => code?.toLowerCase().includes(searchQuery.toLowerCase()))
      : [];
  }, [searchQuery, promoCodeList]);

  // Вспомогательные методы
  const getSalesLabel = (data: PromoCodeListItemRes): string => {
    if (data?.discount.type === TypeDiscountEnum.FREE) {
      return t('promoCode.list.column.discount.label.FREE');
    }

    if (data?.discount.type === TypeDiscountEnum.FIXED) {
      return `${data?.discount.amount} ${t('promoCode.list.column.discount.label.FIXED')}`;
    }

    return `${data?.discount.amount} ${t('promoCode.list.column.discount.label.PERCENT')}`;
  };

  const getNewSortOrder = (sortOrder: SortDirection | null) => {
    switch (sortOrder) {
      case SortDirection.ASC:
        return SortDirection.DESC;
      case SortDirection.DESC:
        return null;
      default:
        return SortDirection.ASC;
    }
  };

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
    setFilters((prevState) => ({ ...prevState }));
  };

  const handleRowClick = (id: number) => () => {
    navigate(`/promoCode/info/${id}`);
  };

  const handleAddButtonClick = () => {
    navigate(`/promoCode/create`);
  };

  const handleFilterChange =
    (key: keyof PromoCodeListFiltersDTO) => (value: number | string | Date | null) => {
      setFilters((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleBackClick = () => {
    !isLoading &&
      organizationId &&
      dispatch(
        getPromoCodeListAction(organizationId, transformFiltersDtoToReq(debouncedFilters), {
          limit,
          page: page - 1,
          qty: fullLength,
        }),
      );
  };

  const handleNextClick = () => {
    !isLoading &&
      organizationId &&
      dispatch(
        getPromoCodeListAction(organizationId, transformFiltersDtoToReq(debouncedFilters), {
          limit,
          page: page + 1,
          qty: fullLength,
        }),
      );
  };

  const handleLimitChange = (limit: number) => {
    !isLoading &&
      organizationId &&
      dispatch(
        getPromoCodeListAction(organizationId, transformFiltersDtoToReq(debouncedFilters), {
          limit,
          page,
          qty: fullLength,
        }),
      );
  };

  const handleSelectPromoCode = (promoCodeId: number, isSelected: boolean) => {
    dispatch(switchSelectPromoCodeAction(promoCodeId, isSelected));
  };

  // render методы
  const renderTableHeaderActions = () => (
    <HorizontalContainer space="xs">
      <TableHeaderButton
        label={t('promoCode.list.actions.print.button.label')}
        disabled={false}
        iconLeft={IconQR as any}
        onClick={handleExportFromPrint}
      />
      <TableHeaderButton
        label={t('promoCode.list.actions.copy.button.label')}
        disabled={false}
        iconLeft={IconCopy as any}
        onClick={handleExportFromClickBoardClick}
      />
      <TableHeaderButton
        label={t('promoCode.list.actions.importExcel.button.label')}
        disabled={false}
        iconLeft={IconDocFilled as any}
        onClick={handleExcelPromoCodeExportClick}
      />
    </HorizontalContainer>
  );

  const renderCodeCell = (data: PromoCodeListItemRes) => (
    <HorizontalContainer align="center" space="xs">
      <div
        className={styles.favoriteContainer}
        onClick={() => handleSelectPromoCode(data.id, !data.isSelected)}
      >
        {data?.isSelected ? (
          <IconFavoriteFill size="s" {...defaultIconProps} className={styles.favorite} />
        ) : (
          <IconFavoriteOutLine size="s" {...defaultIconProps} className={styles.noFavorite} />
        )}
      </div>
      <VerticalContainer space="2xs" className={styles.nameCell} onClick={handleRowClick(data.id)}>
        <HorizontalContainer space="xs">
          <Text size="l" view={data?.isActive ? 'brand' : 'ghost'}>
            {data?.code}
          </Text>
          <Text view="ghost">{data?.qty ? `(${data?.used}/${data?.qty})` : '(∞)'}</Text>
        </HorizontalContainer>
        <Text
          view={data?.isActive ? undefined : 'ghost'}
        >{`${data?.periodUse?.from || '∞'}-${data?.periodUse?.to || '∞'}`}</Text>
      </VerticalContainer>
    </HorizontalContainer>
  );

  const renderCodeFilter = () => (
    <VerticalContainer space="5xl">
      <VerticalContainer space="s">
        <Text size="l" weight="semibold">
          {t('promoCode.list.filters.code.selected.label')}
        </Text>
        <div>
          <ChoiceGroup
            disabled={isLoading}
            value={filters.selected}
            items={selectedPromoCodeFiltersList as string[]}
            name="selected"
            getItemLabel={(item) => t(`promoCode.list.filters.code.selected.${item}`)}
            onChange={({ value }) => {
              handleFilterChange('selected')(value);
            }}
          />
        </div>
      </VerticalContainer>
      <VerticalContainer space="s">
        <Text size="l" weight="semibold">
          {t('promoCode.list.filters.code.active.label')}
        </Text>
        <div>
          <ChoiceGroup
            disabled={isLoading}
            value={filters.active}
            items={activePromoCodeFiltersList as string[]}
            name="active"
            getItemLabel={(item) => t(`promoCode.list.filters.code.active.${item}`)}
            onChange={({ value }) => {
              handleFilterChange('active')(value);
            }}
          />
        </div>
      </VerticalContainer>
      <VerticalContainer space="s">
        <Text size="l" weight="semibold">
          {t('promoCode.list.filters.code.periodUse.label')}
        </Text>
        <DatePicker
          disabled={isLoading}
          type="date-range"
          value={[filters.periodFrom || undefined, filters.periodTo || undefined]}
          onChange={({ value }) => {
            if (!value) {
              handleFilterChange('periodTo')(null);
              handleFilterChange('periodFrom')(null);
            }

            if (value) {
              const to = value[0] || null;
              const from = value[1] || null;

              if (to === filters.periodFrom) {
                from && to && handleFilterChange('periodFrom')(from > to ? to : from);
                handleFilterChange('periodTo')(from);
              }

              if (from === filters.periodTo) {
                handleFilterChange('periodFrom')(to);
                from && to && handleFilterChange('periodTo')(from < to ? to : from);
              }
            }
          }}
        />
        {/*<Checkbox checked={false} label="Неограниченное использование" />*/}
      </VerticalContainer>
      <VerticalContainer space="s">
        <Text size="l" weight="semibold">
          {t('promoCode.list.filters.code.qty.label')}
        </Text>
        <Slider
          disabled={isLoading}
          range
          value={[filters.qtyMin || 0, filters.qtyMax || 0]}
          min={0}
          max={100}
          // max={maxQty}
          onPointerDown={() => setIsNoDebounceChange(true)}
          withTooltip
          onChange={({ value }) => {
            if (value) {
              setIsNoDebounceChange(false);
              handleFilterChange('qtyMin')(value[0] || null);
              handleFilterChange('qtyMax')(value[1] || null);
            }
          }}
        />
        {/*<Checkbox checked={false} label="Неограниченное использование" />*/}
      </VerticalContainer>
    </VerticalContainer>
  );

  const renderDiscountPercentAmountSlider = () => (
    <Slider
      key="DiscountPercentAmountSlider"
      size="l"
      range
      disabled={isLoading}
      value={[filters.discountAmountMin || 0, filters.discountAmountMax || 100]}
      min={0}
      max={100}
      withTooltip
      onPointerDown={() => setIsNoDebounceChange(true)}
      onChange={({ value }) => {
        if (value) {
          setIsNoDebounceChange(false);
          handleFilterChange('discountAmountMin')(value[0] || 0);
          handleFilterChange('discountAmountMax')(value[1] || 0);
        }
      }}
    />
  );

  const renderDiscountFixedAmountSlider = () => (
    <Slider
      key="DiscountFixedAmountSlider"
      size="l"
      range
      disabled={isLoading}
      value={[filters.discountAmountMin || 0, filters.discountAmountMax || 500]}
      min={0}
      max={500}
      withTooltip
      onPointerDown={() => setIsNoDebounceChange(true)}
      onChange={({ value }) => {
        if (value) {
          setIsNoDebounceChange(false);
          handleFilterChange('discountAmountMin')(value[0] || 0);
          handleFilterChange('discountAmountMax')(value[1] || 0);
        }
      }}
    />
  );

  const renderDiscountAmountSlider = () => {
    switch (filters.discountType) {
      case DiscountTypePromoCodeFilterEnum.PERCENT:
        return renderDiscountPercentAmountSlider();
      case DiscountTypePromoCodeFilterEnum.FIXED:
        return renderDiscountFixedAmountSlider();
      default:
        return <Slider size="l" range disabled value={[0, 0]} />;
    }
  };

  const renderDiscountFilter = () => (
    <VerticalContainer>
      <VerticalContainer space="s">
        <Text size="l" weight="semibold">
          {t('promoCode.list.filters.discount.type.label')}
        </Text>
        <VerticalContainer space="xl">
          <div>
            <ChoiceGroup
              disabled={isLoading}
              size="l"
              value={filters.discountType}
              items={discountTypePromoCodeFiltersList as string[]}
              name="discountType"
              getItemLabel={(item) => t(`promoCode.list.filters.discount.type.${item}`)}
              onChange={({ value }) => {
                handleFilterChange('discountType')(value);
                handleFilterChange('discountAmountMin')(null);
                handleFilterChange('discountAmountMax')(null);
              }}
            />
          </div>
          {renderDiscountAmountSlider()}
        </VerticalContainer>
      </VerticalContainer>
    </VerticalContainer>
  );

  const renderHeader = () => (
    <HorizontalContainer>
      <Button
        label={t('promoCode.list.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        // disabled={isLoading}
        width="full"
        form="round"
        leftSide={IconSearch as any}
        // value={searchQuery}
        value={filters.code}
        onChange={({ value }) => handleFilterChange('code')(value)}
        // onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="5xl">
      {renderHeader()}
      <ClassicTable
        className={styles.PromoCodeList}
        withPageSetting
        // withCheckbox
        limit={limit}
        fullLength={fullLength}
        page={page}
        withHeaderActions
        renderTableHeadActions={renderTableHeaderActions}
        sortOrder={{
          code: filters.createdSort,
          discount: null,
          groupName: null,
          description: null,
        }}
        // это просто конфиг наличия фильтров
        filters={{ code: true, discount: false, groupName: false, description: false }}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        onLimitChange={handleLimitChange}
        rows={filterPromoCodeList}
        columns={{
          code: {
            className: styles.codeColumn,
            key: 'code',
            withFilter: true,
            renderFilter: renderCodeFilter,
            filterClassName: classNames(styles.filterModal, styles.codeFilterModal),
            sortable: true,
            type: 'default',
            alignment: 'left',
            title: t('promoCode.list.column.code.title'),
            renderCell: renderCodeCell,
            onSortClick: () => {
              handleFilterChange('createdSort')(getNewSortOrder(filters.createdSort));
            },
          },
          discount: {
            className: styles.discountColumn,
            key: 'discount',
            withFilter: true,
            renderFilter: renderDiscountFilter,
            filterClassName: classNames(styles.filterModal, styles.discountFilterModal),
            // sortable: true,
            type: 'custom',
            renderSort: () => <>Custom sort</>,
            alignment: 'left',
            title: t('promoCode.list.column.discount.title'),
            getItemLabel: getSalesLabel,
          },
          groupName: {
            className: styles.groupNameColumn,
            key: 'groupName',
            withFilter: false,
            // sortable: false,
            alignment: 'left',
            title: t('promoCode.list.column.groupName.title'),
            getItemLabel: (data) => data?.groupName,
          },
          description: {
            className: styles.descriptionColumn,
            // fullWidth: true,
            key: 'description',
            withFilter: false,
            isLongText: true,
            // sortable: false,
            alignment: 'left',
            title: t('promoCode.list.column.description.title'),
            getItemLabel: (data) => data?.description,
          },
        }}
      />
    </VerticalContainer>
  );
};

export default PromoCodeList;
