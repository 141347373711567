import React, { FC, useEffect, useState } from 'react';
import VendistaSettings from './VendistaSettings';
import TelegramNotificationSettings from './TelegramNotificationSettings';
import VerticalContainer from '../../../components/VerticalContainer';
import InstructionsInfo from './InstructionsInfo';
import styles from './IntegrationBaseInfo.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../components/HorizontalContainer';
import ContentCard from '../../../components/ContentCard';
import { IconLogoVendista } from '../../../assets/icon/iconLogoVendista';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconLogoTelegram } from '../../../assets/icon/iconLogoTelegram';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import { selectCheckedVendistaToken } from '../../../state/vendista/selectors';
import { VendistaTokenStatus } from '../../../types/serverInterface/vendistaDTO';
import { selectTelegramNotificationSettingsList } from '../../../state/telemetryNotification/selectors';
import { getTelegramNotificationSettingsByOrganizationIdAction } from '../../../state/telemetryNotification/action';
import ContentCardWithTooltip from '../../../components/withTooltip/ContentCard';
import { useTranslation } from 'react-i18next';

/**
 * Базовая страница интеграции
 */
const IntegrationBaseInfo: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());
  const { state: telegramNotificationList, isLoading } = useAppSelector(
    selectTelegramNotificationSettingsList(),
  );

  const organizationId = getOrganizationId();

  const [isOpenSignInVendistaModal, setIsOpenSignInVendistaModal] = useState(false);
  const [isOpenSignInTelegramModal, setIsOpenSignInTelegramModal] = useState(false);

  useEffect(() => {
    organizationId &&
      dispatch(getTelegramNotificationSettingsByOrganizationIdAction(organizationId));
  }, [organizationId, dispatch]);

  // Обработчики
  const handleSignInVendistaModalOpen = () => {
    if (checkedVendistaToken?.statusToken === VendistaTokenStatus.NOT_PROVIDED) {
      setIsOpenSignInVendistaModal(true);
    }
  };

  const handleSignInVendistaModalClose = () => {
    setIsOpenSignInVendistaModal(false);
  };

  const handleVendistaLogoClick = () => {
    handleSignInVendistaModalOpen();
  };

  const handleSignInTelegramModalOpen = () => {
    if (!telegramNotificationList?.length) {
      setIsOpenSignInTelegramModal(true);
    }
  };

  const handleSignInTelegramModalClose = () => {
    setIsOpenSignInTelegramModal(false);
  };

  const handleTelegramLogoClick = () => {
    handleSignInTelegramModalOpen();
  };

  //render методы
  const renderTextSection = () => (
    <VerticalContainer space={0}>
      <Text size="l" weight="semibold">
        {t('integration.baseInfo.header')}
      </Text>
      <Text size="m" weight="medium" view="secondary">
        {t('integration.baseInfo.information.title')}
      </Text>
    </VerticalContainer>
  );

  // TODO: посмотреть, почему не появляются тултипы
  const renderLogoSection = () => (
    <HorizontalContainer space="s">
      <ContentCardWithTooltip
        tabIndex={0}
        className={styles.logoContainer}
        onClick={handleVendistaLogoClick}
        tooltipProps={{ content: t('integration.baseInfo.vendista.logo.tooltip') }}
      >
        <IconLogoVendista className={styles.logo} size="m" {...defaultIconProps} />
      </ContentCardWithTooltip>
      <ContentCardWithTooltip
        tabIndex={0}
        className={styles.logoContainer}
        onClick={handleTelegramLogoClick}
        tooltipProps={{ content: t('"integration.baseInfo.telegram.logo.tooltip') }}
      >
        <IconLogoTelegram className={styles.logo} size="m" {...defaultIconProps} />
      </ContentCardWithTooltip>
    </HorizontalContainer>
  );

  const renderMainInformationAboutIntegration = () => (
    <ContentCard className={styles.MainInformationAboutIntegration}>
      <VerticalContainer space="m" align="start">
        {renderTextSection()}
        {renderLogoSection()}
      </VerticalContainer>
    </ContentCard>
  );

  const renderVendistaSettings = () => (
    <VendistaSettings
      organizationId={organizationId}
      isOpenSignInModal={isOpenSignInVendistaModal}
      isShowCard={checkedVendistaToken?.statusToken !== VendistaTokenStatus.NOT_PROVIDED}
      handleSignInVendistaModalClose={handleSignInVendistaModalClose}
    />
  );

  const renderTelegramNotificationList = () => {
    if (
      (telegramNotificationList && telegramNotificationList.length > 0) ||
      isOpenSignInTelegramModal
    ) {
      return (
        <TelegramNotificationSettings
          organizationId={organizationId}
          telegramNotificationList={telegramNotificationList}
          isLoading={isLoading}
          isOpenCreateModal={isOpenSignInTelegramModal}
          onCreateClose={handleSignInTelegramModalClose}
        />
      );
    }
    return null;
  };

  return (
    <VerticalContainer space="3xl" className={styles.IntegrationBaseInfo}>
      <VerticalContainer space="m">
        {renderMainInformationAboutIntegration()}
        {renderVendistaSettings()}
        {renderTelegramNotificationList()}
      </VerticalContainer>
      {/*<InstructionsInfo />*/}
    </VerticalContainer>
  );
};

export default IntegrationBaseInfo;
