import { AppDispatch } from '../../app/store';
import {
  createOrganizationThunk,
  deleteOrganizationThunk,
  editOrganizationThunk,
  getOrganizationByIdThunk,
  getOrganizationsListThunk,
  createInviteEmployeeThunk,
  getEmployeesListThunk,
  getEmployeeWithKeyThunk,
  editEmployeeThunk,
  deleteEmployeeThunk,
  getOrganizationDocumentLinksThunk,
  editFileLinksOrganizationThunk,
  getEmployeeDocumentLinksThunk,
  editFileLinksEmployeeThunk,
  getEmployeeWithKeyListThunk,
  toggleKeyThunk,
  generateKeyThunk,
  toggleMachineAccessKeyThunk,
  toggleAllMachineAccessKeyThunk,
  getMembersListWithoutKeyThunk,
  addMachineAccessesKeyThunk,
  getKeyThunk,
  logoutThunk,
  getOrganizationContactsByIdThunk,
  editOrganizationContactsThunk,
  changePasswordThunk,
} from './thunk';
import {
  CreateOrganizationDTO,
  EditOrganizationContactDTO,
  EditOrganizationDTO,
} from '../../types/serverInterface/organizationDTO';
import { CreateInviteEmployeeDTO } from '../../types/serverInterface/invitationDTO';
import { DeleteEmployeeDTO, EditEmployeeDTO } from '../../types/serverInterface/employeeDTO';
import {
  ChangePasswordDTO,
  EditFileLinkEmployeeDTO,
  EditFileLinkOrganizationDTO,
} from '../../types/serverInterface/documentLink';
import { AddMachineAccessesKeyDTO } from '../../types/serverInterface/memberWithoutKeyDTO';
import { logout } from '../auth';

/**
 * Получение списка организаций
 */
export const getOrganizationsListAction = () => (dispatch: AppDispatch) =>
  dispatch(getOrganizationsListThunk());

/**
 * Получение организации по id
 *
 * @param organizationId id организации
 */
export const getOrganizationByIdAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getOrganizationByIdThunk(organizationId));

/**
 * Получение списка контактов организации по id
 *
 * @param organizationId id организации
 */
export const getOrganizationContactsByIdAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getOrganizationContactsByIdThunk(organizationId));
/**
 * Изменение контактов организации
 *
 * @param data данные формы изменения контактов организации
 * @param organizationId id организации
 */
export const editOrganizationContactsAction =
  (data: EditOrganizationContactDTO, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(editOrganizationContactsThunk({ data, organizationId }));
/**
 * Создание организации
 *
 * @param data данные формы создания организации
 */
export const createOrganizationAction = (data: CreateOrganizationDTO) => (dispatch: AppDispatch) =>
  dispatch(createOrganizationThunk(data)).then(() => dispatch(getOrganizationsListAction()));

/**
 * Изменение организации
 *
 * @param data данные формы изменения организации
 * @param organizationId id организации
 */
export const editOrganizationActions =
  (data: EditOrganizationDTO, organizationId: number) => (dispatch: AppDispatch) => {
    dispatch(editOrganizationThunk({ data, organizationId })).then(() =>
      dispatch(getOrganizationsListAction()),
    );
  };

/**
 * Удаление организации
 *
 * @param organizationId id организации
 */
export const deleteOrganizationActions = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteOrganizationThunk(organizationId));

/**
 * Создание приглашения сотрудника
 *
 * @param organizationId id организации
 * @param data данные формы приглашения сотрудника
 */
export const createInviteEmployeeAction =
  (organizationId: number, data: CreateInviteEmployeeDTO) => (dispatch: AppDispatch) =>
    dispatch(createInviteEmployeeThunk({ organizationId, data })).then(() => {
      dispatch(getEmployeesListAction(organizationId));
    });

/**
 * Получение списка сотрудников
 *
 * @param organizationId id организации
 */
export const getEmployeesListAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getEmployeesListThunk(organizationId));

/**
 * Получить список пользователей с ключом
 *
 * @param organizationId id организации
 */
export const getEmployeeWithKeyListAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getEmployeeWithKeyListThunk(organizationId));

/**
 * Переключение активности ключа доступа к автомату
 *
 * @param uuid uuid пользователя
 * @param organizationId id организации
 */
export const toggleKeyAction = (uuid: string, organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(toggleKeyThunk(uuid)).then(() => {
    dispatch(getEmployeeWithKeyListAction(organizationId));
  });

/**
 * Генерация нового ключа
 *
 * @param organizationId id организации
 * @param uuid uuid пользователя
 */
export const generateKeyAction =
  (organizationId: number, uuid: string) => (dispatch: AppDispatch) =>
    dispatch(generateKeyThunk({ organizationId, uuid }));

/**
 * Получение ключа доступа к автомату
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const getKeyAction = (organizationId: number, userUuid: string) => (dispatch: AppDispatch) =>
  dispatch(getKeyThunk({ organizationId, userUuid }));

/**
 * Получение сотрудника
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const getEmployeeWithKeyAction =
  (organizationId: number, userUuid: string) => (dispatch: AppDispatch) =>
    dispatch(getEmployeeWithKeyThunk({ organizationId, userUuid }));

/**
 * Переключение доступа к одному автомату
 *
 * @param userOrganizationId id связи пользователя и организации
 * @param serialNumber серийный номер автомата
 */
export const toggleMachineAccessKeyAction =
  (userOrganizationId: number, serialNumber: string) => (dispatch: AppDispatch) =>
    dispatch(toggleMachineAccessKeyThunk({ userOrganizationId, serialNumber }));

/**
 * Переключение доступа ко всем автоматам
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const toggleAllMachineAccessKeyAction =
  (organizationId: number, userUuid: string) => (dispatch: AppDispatch) =>
    dispatch(toggleAllMachineAccessKeyThunk({ organizationId, userUuid }));

/**
 * Получение списка пользователей без ключа
 *
 * @param organizationId id организации
 */
export const getMembersListWithoutKeyAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getMembersListWithoutKeyThunk(organizationId));

/**
 * Добавление пользователям ключей доступа к автоматам
 *
 * @param organizationId id организации
 * @param memberList список сотрудников, которым добавляется ключ
 */
export const addMachineAccessesKeyAction =
  (organizationId: number, memberList: AddMachineAccessesKeyDTO[]) => (dispatch: AppDispatch) =>
    dispatch(addMachineAccessesKeyThunk({ organizationId, memberList })).then(() => {
      dispatch(getEmployeeWithKeyListAction(organizationId));
    });

/**
 * Изменение сотрудника
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 * @param data данные формы редактирования
 */
export const editEmployeeAction =
  (organizationId: number, userUuid: string, data: EditEmployeeDTO) => (dispatch: AppDispatch) =>
    dispatch(editEmployeeThunk({ organizationId, userUuid, data }));

/**
 * Увольнение сотрудника
 *
 * @param data данные для увольнения сотрудника
 * @param organizationId id организации
 */
export const deleteEmployeeAction =
  (data: DeleteEmployeeDTO, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(deleteEmployeeThunk(data)).then(() => {
      dispatch(getEmployeesListAction(organizationId));
    });

/**
 * Получение списка файлов организации
 *
 * @param organizationId id организации
 */
export const getOrganizationDocumentLinksAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getOrganizationDocumentLinksThunk(organizationId));

/**
 * Изменение списка файлов организации
 *
 * @param data новый список файлов организации
 */
export const editFileLinksOrganizationAction =
  (data: EditFileLinkOrganizationDTO) => (dispatch: AppDispatch) =>
    dispatch(editFileLinksOrganizationThunk(data));

/**
 * Получение списка файлов сотрудника
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const getEmployeeDocumentLinksAction =
  (organizationId: number, userUuid: string) => (dispatch: AppDispatch) =>
    dispatch(getEmployeeDocumentLinksThunk({ organizationId, userUuid }));

/**
 * Изменение списка файлов организации
 *
 * @param data новый список файлов организации
 */
export const editFileLinksEmployeeAction =
  (data: EditFileLinkEmployeeDTO) => (dispatch: AppDispatch) =>
    dispatch(editFileLinksEmployeeThunk(data));

/**
 * Выход из учётной записи
 */
export const logoutAction = () => (dispatch: AppDispatch) =>
  dispatch(logoutThunk()).finally(() => dispatch(logout()));

/**
 * Изменение пароля пользователя
 *
 * @param data данные формы изменения пароля пользователя
 */
export const changePasswordAction = (data: ChangePasswordDTO) => (dispatch: AppDispatch) =>
  dispatch(changePasswordThunk(data));
