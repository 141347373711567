import {
  OrganizationSalesSummaryDTO,
  OrganizationSaleSummary,
  SaleAndTransactionFilterInfo,
  SalesAndTransactionsFilter,
} from '../../../../types/serverInterface/OrganizationSalesDTO';
import { getSalesSummaryAction } from '../../../../state/promoCode/actions';
import { useAppDispatch } from '../../../../app/hooks/store';
import * as ExcelJS from 'exceljs';

export const useExportFromExcel = (
  filters: SalesAndTransactionsFilter,
  filterInfo: SaleAndTransactionFilterInfo,
) => {
  const dispatch = useAppDispatch();

  // Вспомогательные методы
  const generateExcelDoc = (res: OrganizationSalesSummaryDTO) => {
    const salesSummary = res.result || [];

    const { totalSum, totalCount } = salesSummary.reduce(
      (acc, item) => ({
        ...acc,
        totalSum: acc.totalSum + (item.sum || 0),
        totalCount: acc.totalCount + (item.count || 0),
      }),
      { totalSum: 0, totalCount: 0 },
    );

    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${day}.${month}.${year} ${hours}-${minutes}-${seconds}`;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Отчёт по продажам`);

    worksheet.getCell('A1').value = 'Общая сумма';
    worksheet.getCell('B1').value = totalSum;
    worksheet.getCell('A2').value = 'Количество продаж';
    worksheet.getCell('B2').value = totalCount;
    worksheet.getCell('A3').value = 'Средний чек';
    worksheet.getCell('B3').value = (totalSum / totalCount).toFixed(2);
    worksheet.getCell('A4').value = 'Дата формирования запроса';
    worksheet.getCell('B4').value = formattedDateTime;

    worksheet.getCell('C1').value = 'Модель автомата';
    worksheet.getCell('D1').value = filterInfo.machineModelIds?.join(', ') || '-';
    worksheet.getCell('C2').value = 'Торговые точки';
    worksheet.getCell('D2').value = filterInfo.outletIds?.join(', ') || '-';
    worksheet.getCell('C3').value = 'Автоматы';
    worksheet.getCell('D3').value = filterInfo.machineIds?.join(', ') || '-';

    worksheet.getCell('C4').value = 'Категории';
    worksheet.getCell('D4').value = filterInfo.cellCategoryIds?.join(', ') || '-';
    worksheet.getCell('C5').value = 'Назначения';
    worksheet.getCell('D5').value = filterInfo.cellPurposeIds?.join(', ') || '-';
    worksheet.getCell('C6').value = 'Виды';
    worksheet.getCell('D6').value = filterInfo.viewIds?.join(', ') || '-';
    worksheet.getCell('C7').value = 'Вкусы';
    worksheet.getCell('D7').value = filterInfo.tasteIds?.join(', ') || '-';

    worksheet.getCell('C8').value = 'Бренды';
    worksheet.getCell('D8').value = filterInfo.brandIds?.join(', ') || '-';
    worksheet.getCell('C9').value = 'Линейки';
    worksheet.getCell('D9').value = filterInfo.productLineIds?.join(', ') || '-';
    worksheet.getCell('C10').value = 'Продукты';
    worksheet.getCell('D10').value = filterInfo.productIds?.join(', ') || '-';

    worksheet.getCell('E1').value = 'Тип скидки';
    worksheet.getCell('F1').value = filterInfo.discountType || '-';
    worksheet.getCell('E2').value = 'Минимальная скидка';
    worksheet.getCell('F2').value = filterInfo.discountAmountMin || '-';
    worksheet.getCell('E3').value = 'Максимальная скидка';
    worksheet.getCell('F3').value = filterInfo.discountAmountMax || '-';
    worksheet.getCell('E4').value = 'Минимальная цена';
    worksheet.getCell('F4').value = filterInfo.priceAmountMin || '-';
    worksheet.getCell('E5').value = 'Максимальная цена';
    worksheet.getCell('F5').value = filterInfo.priceAmountMax || '-';
    worksheet.getCell('E6').value = 'Начальная дата';
    worksheet.getCell('F6').value = filterInfo.dateStartSale || '-';
    worksheet.getCell('E7').value = 'Конечная дата';
    worksheet.getCell('F7').value = filterInfo.dateEndSale || '-';
    worksheet.getCell('E8').value = 'Тип промокода';
    worksheet.getCell('F8').value = filterInfo.usageType || '-';
    worksheet.getCell('E9').value = 'Статус транзакции';
    worksheet.getCell('F9').value = filterInfo.transactionStatus || '-';

    worksheet.getColumn('A').width = 50;
    worksheet.getColumn('B').width = 30;
    worksheet.getColumn('C').width = 30;
    worksheet.getColumn('D').width = 35;
    worksheet.getColumn('E').width = 30;
    worksheet.getColumn('F').width = 35;

    worksheet.getCell('A12').value = 'Название продукта';
    worksheet.getCell('B12').value = 'Сумма продаж';
    worksheet.getCell('C12').value = 'Количество продаж';
    worksheet.getCell('D12').value = 'Процент от общей суммы';

    const rowExcelContentStartIndex = 13;

    salesSummary.forEach(({ sum, name, count }, index) => {
      const rowExcelIndex = index + rowExcelContentStartIndex;

      worksheet.getCell(`A${rowExcelIndex}`).value = name;
      worksheet.getCell(`B${rowExcelIndex}`).value = sum;
      worksheet.getCell(`C${rowExcelIndex}`).value = count;
      worksheet.getCell(`D${rowExcelIndex}`).value = +((sum / totalSum) * 100).toFixed(2) + '%';
    });

    //Начинаем работать с внешним видом exel файла
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.style.font = { size: 16 };
        // Включаем автоматический перенос текста по словам
        cell.alignment = { wrapText: true, horizontal: 'left' };
      });
    });

    // создание файла exсel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Сводный отчёт по продажам ${formattedDateTime}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  // Обработчики
  const handleExcelExportClick = () => {
    dispatch(getSalesSummaryAction(filters))
      .then(generateExcelDoc)
      .catch((err) => {
        console.log('err, ', err);
      });
  };

  return { handleExcelExportClick };
};
