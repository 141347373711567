import React, { FC } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './QrPromoCodeModal.module.scss';
import { QRCode } from 'react-qrcode-logo';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import html2canvas from 'html2canvas';
import { IconPrinterStroked } from '../../../../assets/icon/iconPrinterStroked';
import { IconDownload } from '../../../../assets/icon/iconDownload';
import { useTranslation } from 'react-i18next';

type QrPromoCodeModalProps = {
  isOpen: boolean;
  promoCode: string;
  onClose: () => void;
};

/**
 * Модальное окно qr кода промокода
 */
const QrPromoCodeModal: FC<QrPromoCodeModalProps> = ({ isOpen, promoCode, onClose }) => {
  const { t } = useTranslation();

  // Путь к изображению в папке public
  const logoImagePath = process.env.PUBLIC_URL + '/img/qr-logo.png';

  // Обработчики
  const handleDownload = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const link = document.createElement('a');
      link.download = `promoCode-${promoCode}-qr-code.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handlePrint = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const imgData = canvas.toDataURL();

      // Создать новый элемент img с изображением для печати
      const img = new Image();
      img.onload = function () {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.open();
          document.write('<div>');
          printWindow.document.write('<img width="200px" src="' + img.src + '" />');

          printWindow.document.write(
            '<p id="key" style="font-size: 18px; color: #21201f; font-weight: 600; margin-left: 45px">key</p>',
          );
          document.write('</div>');

          const element = printWindow.document.getElementById('key');
          if (element) {
            element.innerText = promoCode;

            setTimeout(() => {
              console.log('Текст изменен после задержки');
            }, 500);
          }

          printWindow.document.close();

          new Promise<void>(function (resolve) {
            resolve();
          }).then(function () {
            printWindow.print();
          });
        }
      };
      img.src = imgData;
    });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label={t('promoCode.info.qr.back.button.label')} view="clear" onClick={onClose} />
    </>
  );

  return (
    <DefaultModal
      className={styles.QrKeyModal}
      // className={classNames(styles.QrKeyModal, isPrint && styles.isPrint)}
      isOpen={isOpen}
      modalTitle={t('promoCode.info.qr.modal.title')}
      renderActions={renderActions}
      onClose={onClose}
    >
      <div className={styles.card}>
        <VerticalContainer space="2xl" align="center">
          {/*<QrKeyPrint key={key} userName={userName} />*/}
          <div className={styles.qrContainer}>
            <QRCode
              value={promoCode}
              qrStyle="fluid"
              logoImage={logoImagePath}
              logoPadding={5}
              logoWidth={53}
              logoHeight={53}
              size={250}
            />
          </div>
          <Text size="l" weight="semibold">
            {promoCode}
          </Text>
          <HorizontalContainer space="2xl" align="center" justify="center">
            <Button
              size="l"
              view="ghost"
              onlyIcon
              iconLeft={IconPrinterStroked as any}
              onClick={handlePrint}
            />
            <Button
              size="l"
              view="ghost"
              onlyIcon
              iconLeft={IconDownload as any}
              onClick={handleDownload}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </div>
    </DefaultModal>
  );
};

export default QrPromoCodeModal;
