import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  deleteEmployeeAction,
  getEmployeesListAction,
} from '../../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { EmployeeDTO } from '../../../../types/serverInterface/employeeDTO';
import { Roles } from '../../../../types/serverInterface/cabinetDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';
import DeleteMember from './DeleteMember';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconProfileAccepted } from '../../../../assets/icon/iconProfileAccepted';
import VerticalContainer from '../../../../components/VerticalContainer';
import CreateMember from './CreateMember';
import { selectEmployeesList } from '../../../../state/organization/selectors';
import TableContextMenu from '../../../../components/TableContextMenu';
import styles from '../../../ProductBasePage/Brand/Brand.module.scss';
import { IconKebab } from '@consta/uikit/IconKebab';
import { TableContextMenuItem } from '../../../../components/TableContextMenu/TableContextMenu';
import ChangeMemberPassword from './ChangeMemberPassword';

/**
 * Свойства компонента MembersTable
 */
type MembersTableProps = {
  /**
   * id организации
   */
  organizationId: number;
};

enum MembersContextmenuItems {
  EDIT_MEMBER = 'EDIT_MEMBER',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DELETE_MEMBER = 'DELETE_MEMBER',
}

const memberContextMenuItems = [
  // {
  //   name: MembersContextmenuItems.EDIT_MEMBER,
  //   label: 'Редактировать',
  //   group: 1,
  // },
  {
    name: MembersContextmenuItems.CHANGE_PASSWORD,
    label: 'organization.members.list.changePassword.button.label',
    group: 1,
  },
  {
    name: MembersContextmenuItems.DELETE_MEMBER,
    label: 'organization.members.list.delete.button.label',
    group: 1,
  },
];

/**
 * Таблица со списком сотрудников организации
 */
const MembersTable: FC<MembersTableProps> = ({ organizationId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const employeesList = useAppSelector(selectEmployeesList());

  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<EmployeeDTO | null>(null);

  useEffect(() => {
    organizationId && dispatch(getEmployeesListAction(organizationId));
  }, [dispatch, organizationId]);

  const filterEmployeesList = useMemo(() => {
    return employeesList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, employeesList]);

  // Обработчики
  const handleDelete = () => {
    setIsDeleteLoading(true);
    selectedMember &&
      dispatch(
        deleteEmployeeAction({ organizationId, userUuid: selectedMember.uuid }, organizationId),
      ).then(() => {
        setSelectedMember(null);
        setIsDeleteOpen(false);
        setIsDeleteLoading(false);
      });
  };

  const handleCancelDeleteClick = () => {
    setSelectedMember(null);
    setIsDeleteOpen(false);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleContextMenuItemClick = (member: EmployeeDTO) => (item: TableContextMenuItem) => {
    switch (item.name) {
      case MembersContextmenuItems.DELETE_MEMBER: {
        setSelectedMember(member);
        setIsDeleteOpen(true);

        return;
      }
      case MembersContextmenuItems.CHANGE_PASSWORD: {
        setSelectedMember(member);
        setIsChangePasswordOpen(true);
        return;
      }

      default:
        return;
    }
  };

  const handleChangeMemberPasswordClose = () => {
    setIsChangePasswordOpen(false);
    setSelectedMember(null);
  };

  // render методы
  const renderTableActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label={t('organization.members.signUp.button.label')}
        iconLeft={IconProfileAccepted as any}
        onClick={() => setIsCreateOpen(true)}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="3xl">
      <CreateMember isOpen={isCreateOpen} onClose={() => setIsCreateOpen(false)} />
      {renderTableActions()}
      {isDeleteOpen && (
        <DeleteMember
          isDeleteLoading={isDeleteLoading}
          member={selectedMember}
          onClose={handleCancelDeleteClick}
          onDelete={handleDelete}
        />
      )}
      {isChangePasswordOpen && (
        <ChangeMemberPassword
          member={selectedMember}
          organizationId={organizationId}
          onClose={handleChangeMemberPasswordClose}
        />
      )}
      <TableCardWithBackground
        rows={filterEmployeesList}
        columns={{
          name: {
            key: 'name',
            title: t('organization.members.table.fullName.column.title'),
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ name, surname, patronymic }) =>
              `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`,
          },
          position: {
            key: 'position',
            title: t('organization.members.table.role.column.title'),
            alignment: 'left',
            width: '25%',
            getItemLabel: ({ position }) => t(`roles.${position}`),
          },
          email: {
            key: 'email',
            title: t('organization.members.table.email.column.title'),
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ email }) => email || '',
          },
          phone: {
            key: 'phone',
            title: t('organization.members.table.phone.column.title'),
            alignment: 'left',
            width: '11%',
            getItemLabel: ({ phone }) => phone,
          },
          uuid: {
            key: 'uuid',
            title: '',
            alignment: 'left',
            width: '6%',
            renderCell: (data) => (
              <HorizontalContainer space="s">
                {data.position !== Roles.ORGANIZATION_OWNER && (
                  <TableContextMenu
                    className={styles.brandContextMenu}
                    buttonIcon={IconKebab}
                    items={memberContextMenuItems}
                    onItemClick={handleContextMenuItemClick(data)}
                  />
                )}
              </HorizontalContainer>
            ),
          },
        }}
      />
    </VerticalContainer>
  );
};

export default MembersTable;
