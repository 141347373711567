import { FC } from 'react';
import styles from './LeftMenuItem.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LeftMenuItemProps } from './types';

/**
 *  Элемент выпадающего списка левого меню
 */
const LeftMenuItem: FC<LeftMenuItemProps> = ({ item, onClose }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSelect = pathname === item.path;

  // Обработчики
  const handleClick = () => {
    navigate(item.path);
    onClose && onClose();
  };

  return (
    <div className={styles.HorizontalContainer}>
      <div className={styles.leftLine}>
        <div className={styles.spacer} />
        <div
          className={classNames(styles.LeftMenuItem, isSelect && styles.select)}
          onClick={handleClick}
        >
          <Text weight="medium" view="secondary" size="xs">
            {t(item.label)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LeftMenuItem;
