import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectMachineList } from '../../../state/machineControl/selectors';
import { useEffect, useMemo, useState } from 'react';
import {
  MachineFiltersDTO,
  MachineListItem,
  SalesFilters,
} from '../../../types/serverInterface/machineListDTO';
import { getMachineListWithFiltersAction } from '../../../state/machineControl/actions';
import {
  checkVendistaTokenAction,
  getMachineVendistaSettingListByOrganizationIdAction,
} from '../../../state/vendista/action';
import {
  selectCheckedVendistaToken,
  selectMachineVendistaSettingList,
} from '../../../state/vendista/selectors';
import { MachineVendistaSettingListItem } from '../../../types/serverInterface/vendistaDTO';

/**
 * Дефолтное значение фильтра списка автоматов
 */
export const machineListInitialFilters: MachineFiltersDTO = {
  name: null,
  isArchived: false,
  machineModelIds: null,
  salesFilter: SalesFilters.Last30Days,
  shutdownReasonFilter: null,
  organizationId: null,
  sortSaleDirection: null,
  sortCupsDirection: null,
  sortNameDirection: null,
  sortStatusDirection: null,
  sortWaterDirection: null,
  statusColors: null,
  connectionStatus: null,
  page: 1,
  limit: 1000,
  items: 10,
};

export type FormatedMachineListItem = MachineListItem & {
  vendista: MachineVendistaSettingListItem | null | undefined;
};

/**
 * hoc получения всех необходимых данных для страницы списка автоматов
 */
export const useMachineList = (organizationId: number | null) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { state } = useAppSelector(selectMachineList());
  const { state: vendistaSettingList } = useAppSelector(selectMachineVendistaSettingList());
  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());

  const [filters, setFilters] = useState<MachineFiltersDTO>({
    ...machineListInitialFilters,
    organizationId,
  });

  const machineList: FormatedMachineListItem[] | undefined = useMemo(
    () =>
      state?.results.map((machine) => ({
        ...machine,
        vendista: vendistaSettingList?.find(({ machineId }) => machineId === machine.id),
      })),
    [state, vendistaSettingList],
  );

  useEffect(() => {
    state &&
      setFilters({
        ...state.parameters,
        page: state.page,
        limit: state.limit,
        items: state.items,
      });
  }, [state]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getMachineVendistaSettingListByOrganizationIdAction(organizationId));
      dispatch(checkVendistaTokenAction(organizationId));
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    console.log('useEffect');

    setIsLoading(true);
    dispatch(getMachineListWithFiltersAction(filters)).then(() => {
      setIsLoading(false);
    });

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      console.log('return useEffect');
      organizationId &&
        dispatch(getMachineListWithFiltersAction(filters)).then(() => {
          setIsLoading(false);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, [
    dispatch,
    organizationId,
    // Попытка исключить лишние запросы. items меняется при применении фильтра к бэку В будущем нужно будет разделить
    JSON.stringify({ ...filters, page: null, limit: null, items: null }),
  ]);

  return {
    machineList,
    checkedVendistaToken,
    allMachineCount:
      (state?.machineAtStatusesCount.success || 0) +
      (state?.machineAtStatusesCount.warning || 0) +
      (state?.machineAtStatusesCount.error || 0),
    machineAtStatusesCount: state?.machineAtStatusesCount,
    machineAtConnectionStatusesCount: state?.machineAtConnectionStatusesCount,
    machineAtModelsCount: state?.machineAtModel,
    archivedCount: state?.archivedCount,
    machineAtShutdownCount: state?.machineAtShutdownCount,
    isLoading,
    filters,
    setFilters,
  };
};
