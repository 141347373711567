import { AbstractApiModule } from '../../abstractApiModule';
import {
  CreateOrganizationDTO,
  DetailedOrganizationDTO,
  EditOrganizationDTO,
  OrganizationDTO,
  EditOrganizationContactDTO,
  ContactOrganizationListDTO,
} from '../../../../types/serverInterface/organizationDTO';
import { CreateInviteEmployeeDTO } from '../../../../types/serverInterface/invitationDTO';
import {
  DeleteEmployeeDTO,
  EditEmployeeDTO,
  EmployeeDTO,
} from '../../../../types/serverInterface/employeeDTO';
import {
  ChangePasswordDTO,
  DocumentLinkDTO,
  EditFileLinkEmployeeDTO,
  EditFileLinkOrganizationDTO,
} from '../../../../types/serverInterface/documentLink';
import { EmployeeWithKeyDTO } from '../../../../types/serverInterface/employeeWithKeyDTO';
import { EmployeeWithKeyElementDTO } from '../../../../types/serverInterface/employeeWithKeyElementDTO';
import {
  AddMachineAccessesKeyDTO,
  MemberWithoutKeyDTO,
} from '../../../../types/serverInterface/memberWithoutKeyDTO';
import { organizationBaseUrl } from '../../../../consts';

const getDataFromServer = async <T>(data: T): Promise<T> => {
  return await new Promise((resolve) => {
    setTimeout(() => resolve(data), 100);
  });
};

export class OrganizationModule extends AbstractApiModule {
  /**
   * Получение списка организаций
   */
  fetchOrganizationsList() {
    return this.request.get<void, OrganizationDTO[]>(`${organizationBaseUrl}/organization/list`);
  }

  /**
   * Получение организации по id
   *
   * @param organizationId id организации
   */
  fetchOrganizationById(organizationId: number) {
    return this.request.get<number, DetailedOrganizationDTO>(
      `${organizationBaseUrl}/organization/element/${organizationId}`,
    );
  }

  /**
   * Получение списка контактов организации по id
   *
   * @param organizationId id организации
   */
  fetchOrganizationContactsById(organizationId: number) {
    return this.request.get<number, ContactOrganizationListDTO>(
      `${organizationBaseUrl}/organization-contact/list/${organizationId}`,
    );
  }

  /**
   * Создание организации
   *
   * @param data данные формы создания организации
   */
  create(data: CreateOrganizationDTO): Promise<{ id: number }> {
    return this.request.post<CreateOrganizationDTO, { id: number }>(
      `${organizationBaseUrl}/organization/create`,
      data,
    );
  }

  /**
   * Изменение организации
   *
   * @param data данные формы изменения организации
   * @param organizationId id организации
   */
  edit({ data, organizationId }: { data: EditOrganizationDTO; organizationId: number }) {
    return this.request.patch<EditOrganizationDTO, void>(
      `${organizationBaseUrl}/organization/edit/${organizationId}`,
      data,
    );
  }

  /**
   * Изменение контактов организации
   *
   * @param data данные формы изменения контактов организации
   * @param organizationId id организации
   */
  editOrganizationContact({
    data,
    organizationId,
  }: {
    data: EditOrganizationContactDTO;
    organizationId: number;
  }) {
    return this.request.patch<EditOrganizationContactDTO, void>(
      `${organizationBaseUrl}/organization-contact/edit/${organizationId}`,
      data,
    );
  }

  /**
   * Удаление организации
   *
   * @param organizationId id организации
   */
  delete(organizationId: number): Promise<void> {
    return this.request.delete(`${organizationBaseUrl}/organization/${organizationId}`);
  }

  /**
   * Приглашение сотрудника
   *
   * @param data данные формы приглашения сотрудника
   * @param organizationId id организации
   */
  createInviteEmployee({
    data,
    organizationId,
  }: {
    organizationId: number;
    data: CreateInviteEmployeeDTO;
  }): Promise<{ hash: string; uuid: string }> {
    return this.request.post(`${organizationBaseUrl}/user/${organizationId}/create`, data);
  }

  /**
   * Получить список сотрудников
   *
   * @param organizationId id организации
   */
  fetchEmployeesList(organizationId: number): Promise<EmployeeDTO[]> {
    return this.request.get(`${organizationBaseUrl}/employee/${organizationId}`);
  }

  /**
   * Получить список пользователей с ключом
   *
   * @param organizationId id организации
   */
  getEmployeeWithKeyList(organizationId: number): Promise<EmployeeWithKeyDTO[]> {
    return this.request.get(`${organizationBaseUrl}/employee/list-with-key/${organizationId}`);
  }

  /**
   * Переключение активности ключа доступа к автомату
   *
   * @param uuid uuid пользователя
   */
  toggleKey(uuid: string): Promise<void> {
    return this.request.post(`${organizationBaseUrl}/machine/toggle-active-key/${uuid}`);
  }

  /**
   * Генерация нового ключа
   *
   * @param organizationId id организации
   * @param uuid uuid пользователя
   */
  generateKey(organizationId: number, uuid: string): Promise<{ key: string }> {
    return this.request.post(`${organizationBaseUrl}/machine/access/${organizationId}`, { uuid });
  }

  /**
   * Получение ключа доступа к автомату
   *
   * @param organizationId id организации
   * @param userUuid uuid пользователя
   */
  getKey(organizationId: number, userUuid: string): Promise<{ key: string }> {
    return this.request.get(`${organizationBaseUrl}/machine/key/${organizationId}/${userUuid}`);
  }

  /**
   * Получить сотрудника
   *
   * @param organizationId id организации
   * @param userUuid uuid пользователя
   */
  fetchEmployeeWithKey({
    organizationId,
    userUuid,
  }: {
    organizationId: number;
    userUuid: string;
  }): Promise<EmployeeWithKeyElementDTO> {
    return this.request.get(
      `${organizationBaseUrl}/employee/element/${organizationId}/${userUuid}`,
    );
  }

  /**
   * Переключение доступа к одному автомату
   *
   * @param userOrganizationId id связи пользователя и организации
   * @param serialNumber серийный номер автомата
   */
  toggleMachineAccessKey(userOrganizationId: number, serialNumber: string): Promise<string> {
    return this.request.post(
      `${organizationBaseUrl}/access-machine/toggle/${userOrganizationId}/${serialNumber}`,
    );
  }

  /**
   * Переключение доступа ко всем автоматам
   *
   * @param organizationId id организации
   * @param userUuid uuid пользователя
   */
  toggleAllMachineAccessKey(organizationId: number, userUuid: string): Promise<string> {
    return this.request.post(
      `${organizationBaseUrl}/access-machine/toggle-all/${organizationId}/${userUuid}`,
    );
  }

  /**
   * Получение списка пользователей без ключа
   *
   * @param organizationId id организации
   */
  getMembersListWithoutKey(organizationId: number): Promise<MemberWithoutKeyDTO[]> {
    return this.request.get(`${organizationBaseUrl}/employee/list-without-key/${organizationId}`);
  }

  /**
   * Добавление пользователям ключей доступа к автоматам
   *
   * @param organizationId id организации
   * @param memberList список сотрудников, которым добавляется ключ
   */
  addMachineAccessesKey(
    organizationId: number,
    memberList: AddMachineAccessesKeyDTO[],
  ): Promise<void> {
    return this.request.post(
      `${organizationBaseUrl}/machine/accesses/${organizationId}`,
      memberList,
    );
  }

  /**
   * Изменение сотрудника
   *
   * @param organizationId id организации
   * @param userUuid uuid пользователя
   * @param data данные формы редактирования
   */
  editEmployee({
    organizationId,
    userUuid,
    data,
  }: {
    organizationId: number;
    userUuid: string;
    data: EditEmployeeDTO;
  }): Promise<void> {
    return this.request.patch<EditEmployeeDTO, void>(
      `${organizationBaseUrl}/employee/${organizationId}/${userUuid}`,
      data,
    );
  }

  /**
   * Увольнение сотрудника
   *
   * @param data данные для увольнения сотрудника
   */
  deleteEmployee(data: DeleteEmployeeDTO): Promise<void> {
    return this.request.patch<DeleteEmployeeDTO, void>(
      `${organizationBaseUrl}/employee/dismissal`,
      data,
    );
  }

  /**
   * Получение списка документов организации
   *
   * @param organizationId id организации
   */
  fetchOrganizationDocs(organizationId: number): Promise<DocumentLinkDTO[]> {
    return this.request.get(`${organizationBaseUrl}/file-link/organization/${organizationId}`);
  }

  /**
   * Изменение списка файлов организации
   *
   * @param data новый список файлов организации
   */
  updateFileLinksOrganization(data: EditFileLinkOrganizationDTO): Promise<void> {
    return this.request.post(`${organizationBaseUrl}/file-link/organization`, data);
  }

  /**
   * Получение списка файлов сотрудника
   *
   * @param organizationId id организации сотрудника
   * @param userUuid uuid пользователя
   */
  fetchEmployeeDocs(organizationId: number, userUuid: string): Promise<DocumentLinkDTO[]> {
    return this.request.get(
      `${organizationBaseUrl}/file-link/user-organization/${organizationId}/${userUuid}`,
    );
  }

  /**
   * Изменение списка файлов организации
   *
   * @param data новый список файлов организации
   */
  updateFileLinksEmployee(data: EditFileLinkEmployeeDTO): Promise<void> {
    return this.request.post(`${organizationBaseUrl}/file-link/user-organization`, data);
  }

  /**
   * Выход из учётной записи
   */
  logout() {
    return this.request.get(`${organizationBaseUrl}/user/logout`);
  }

  /**
   * Изменение пароля пользователя
   *
   * @param data данные формы изменения пароля пользователя
   */
  changePassword(data: ChangePasswordDTO) {
    return this.request.post<ChangePasswordDTO, void>(
      `${organizationBaseUrl}/user/change-password`,
      data,
    );
  }
}
