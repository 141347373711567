import React from 'react';
import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../../../types/types';
import { useAppDispatch } from '../../../../app/hooks/store';
import { getPromoCodeListByExportAction } from '../../../../state/promoCode/actions';
import QRCode from 'qrcode';

/**
 * Хук печати списка промокодов
 */
const useExportPromoCodeFromPrint = ({
  organizationId,
  filters,
  pagination,
}: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
}) => {
  const dispatch = useAppDispatch();

  const handlePrint = () => {
    dispatch(getPromoCodeListByExportAction(organizationId, filters, pagination)).then(
      async (res) => {
        // Собираем промисы для генерации QR-кодов
        const qrCodePromises = res.data.map(({ code }) => {
          return new Promise((resolve, reject) => {
            const promoCode = code || '';
            QRCode.toDataURL(promoCode, { width: 200 }, function (err, url) {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                resolve({ promoCode, url });
              }
            });
          });
        });

        try {
          // Ждем, пока все QR-коды будут сгенерированы
          const qrCodes = await Promise.all(qrCodePromises);

          // Создаем содержимое для печати
          let printContent = `
            <html>
              <head>
                <title>Печать QR-кодов</title>
                <script>
                  window.onload = function() {
                    window.print();
                    window.close();
                  };
                </script>
                <style>
                  /* Ваши стили */
                  body {
                    margin: 0;
                    padding: 0;
                  }
                  .qr-container {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 10px;
                  }
                  .qr-item {
                    break-inside: avoid;
                    text-align: center;
                  }
                  .text {
                    font-size: 18px; 
                    line-height: 18px; 
                    color: #21201f; 
                    font-weight: 600; 
                    margin: 0;
                  }
                </style>
              </head>
              <body>
                <div class="qr-container">
          `;

          // Добавляем каждый QR-код и промо-код в содержимое
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          qrCodes.forEach(({ promoCode, url }) => {
            printContent += `
              <div class="qr-item">
                <img src="${url}" width="200px" alt="QR Code" />
                <p class="text">${promoCode}</p>
              </div>
            `;
          });

          printContent += `
                </div>
              </body>
            </html>
          `;

          // Открываем новое окно и вставляем содержимое
          const printWindow = window.open('', '_blank');
          if (printWindow) {
            printWindow.document.open();
            printWindow.document.write(printContent);
            printWindow.document.close();
          }
        } catch (error) {
          console.error('Ошибка при генерации QR-кодов:', error);
        }
      },
    );
  };

  return {
    handleExportFromPrint: handlePrint,
  };
};

export default useExportPromoCodeFromPrint;
