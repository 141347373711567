import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateOrganizationDTO,
  DetailedOrganizationDTO,
  EditOrganizationDTO,
  ContactOrganization,
  OrganizationDTO,
  EditOrganizationContactDTO,
} from '../../types/serverInterface/organizationDTO';
import { api } from '../../app/api';
import { CreateInviteEmployeeDTO } from '../../types/serverInterface/invitationDTO';
import {
  DeleteEmployeeDTO,
  EditEmployeeDTO,
  EmployeeDTO,
} from '../../types/serverInterface/employeeDTO';
import {
  ChangePasswordDTO,
  DocumentLinkDTO,
  EditFileLinkEmployeeDTO,
  EditFileLinkOrganizationDTO,
} from '../../types/serverInterface/documentLink';
import { EmployeeWithKeyDTO } from '../../types/serverInterface/employeeWithKeyDTO';
import { EmployeeWithKeyElementDTO } from '../../types/serverInterface/employeeWithKeyElementDTO';
import {
  AddMachineAccessesKeyDTO,
  MemberWithoutKeyDTO,
} from '../../types/serverInterface/memberWithoutKeyDTO';

/**
 * Получение списка организаций
 */
export const getOrganizationsListThunk = createAsyncThunk<OrganizationDTO[]>(
  'getOrganizationsList',
  async () => {
    return await api.organizations.fetchOrganizationsList();
  },
);

/**
 * Получение организации по id
 *
 * @param organizationId id организации
 */
export const getOrganizationByIdThunk = createAsyncThunk<DetailedOrganizationDTO, number>(
  'getOrganizationById',
  async (organizationId: number) => {
    return await api.organizations.fetchOrganizationById(organizationId);
  },
);

/**
 * Получение контактов организации
 *
 * @param organizationId id организации
 */
export const getOrganizationContactsByIdThunk = createAsyncThunk<ContactOrganization[], number>(
  'getOrganizationContactsById',
  async (organizationId: number) => {
    return await api.organizations.fetchOrganizationContactsById(organizationId);
  },
);

/**
 * Изменение контактов организации
 *
 * @param data список контактов организации
 * @param organizationId id организации
 */
export const editOrganizationContactsThunk = createAsyncThunk<
  void,
  { data: EditOrganizationContactDTO; organizationId: number }
>('editOrganizationContacts', async ({ data, organizationId }) => {
  return await api.organizations.editOrganizationContact({ data, organizationId });
});

/**
 * Создание организации
 */
export const createOrganizationThunk = createAsyncThunk<{ id: number }, CreateOrganizationDTO>(
  'createOrganization',
  async (data) => {
    return await api.organizations.create(data);
  },
);

/**
 * Изменение организации
 *
 * @param data измененная базовая информация об организации
 * @param organizationId id организации
 */
export const editOrganizationThunk = createAsyncThunk<
  void,
  { data: EditOrganizationDTO; organizationId: number }
>('editOrganization', async ({ data, organizationId }) => {
  return await api.organizations.edit({ data, organizationId });
});

/**
 * Удаление организации
 */
export const deleteOrganizationThunk = createAsyncThunk<void, number>(
  'deleteOrganization',
  async (id) => {
    return await api.organizations.delete(id);
  },
);

/**
 * Создание приглашения сотрудника
 */
export const createInviteEmployeeThunk = createAsyncThunk<
  { hash: string; uuid: string },
  {
    organizationId: number;
    data: CreateInviteEmployeeDTO;
  }
>('inviteEmployee', async (data) => {
  return await api.organizations.createInviteEmployee(data);
});

/**
 * Получение списка сотрудников
 */
export const getEmployeesListThunk = createAsyncThunk<EmployeeDTO[], number>(
  'getEmployeesList',
  async (organizationId: number) => {
    return await api.organizations.fetchEmployeesList(organizationId);
  },
);

/**
 * Получить список пользователей с ключом
 *
 * @param organizationId id организации
 */
export const getEmployeeWithKeyListThunk = createAsyncThunk<EmployeeWithKeyDTO[], number>(
  'getEmployeeWithKeyList',
  async (organizationId) => {
    return await api.organizations.getEmployeeWithKeyList(organizationId);
  },
);

/**
 * Переключение активности ключа доступа к автомату
 *
 * @param uuid uuid пользователя
 */
export const toggleKeyThunk = createAsyncThunk<void, string>('toggleKey', async (key) => {
  return await api.organizations.toggleKey(key);
});

/**
 * Генерация нового ключа
 *
 * @param organizationId id организации
 * @param uuid uuid пользователя
 */
export const generateKeyThunk = createAsyncThunk<
  { key: string },
  { organizationId: number; uuid: string }
>('generateKey', async ({ organizationId, uuid }) => {
  return await api.organizations.generateKey(organizationId, uuid);
});

/**
 * Получение ключа доступа к автомату
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const getKeyThunk = createAsyncThunk<
  { key: string },
  { organizationId: number; userUuid: string }
>('getKey', async ({ organizationId, userUuid }) => {
  return await api.organizations.getKey(organizationId, userUuid);
});

/**
 * Получение сотрудника
 */
export const getEmployeeWithKeyThunk = createAsyncThunk<
  EmployeeWithKeyElementDTO,
  { organizationId: number; userUuid: string }
>('getEmployeeWithKey', async (data) => {
  return await api.organizations.fetchEmployeeWithKey(data);
});

/**
 * Переключение доступа к одному автомату
 *
 * @param userOrganizationId id связи пользователя и организации
 * @param serialNumber серийный номер автомата
 */
export const toggleMachineAccessKeyThunk = createAsyncThunk<
  string,
  { userOrganizationId: number; serialNumber: string }
>('toggleMachineAccessKey', async ({ userOrganizationId, serialNumber }) => {
  return await api.organizations.toggleMachineAccessKey(userOrganizationId, serialNumber);
});

/**
 * Переключение доступа ко всем автоматам
 *
 * @param organizationId id организации
 * @param userUuid uuid пользователя
 */
export const toggleAllMachineAccessKeyThunk = createAsyncThunk<
  string,
  { organizationId: number; userUuid: string }
>('toggleAllMachineAccessKey', async ({ organizationId, userUuid }) => {
  return await api.organizations.toggleAllMachineAccessKey(organizationId, userUuid);
});

/**
 * Получение списка пользователей без ключа
 *
 * @param organizationId id организации
 */
export const getMembersListWithoutKeyThunk = createAsyncThunk<MemberWithoutKeyDTO[], number>(
  'getMembersListWithoutKey',
  async (organizationId) => {
    return await api.organizations.getMembersListWithoutKey(organizationId);
  },
);

/**
 * Добавление пользователям ключей доступа к автоматам
 *
 * @param organizationId id организации
 * @param memberList список сотрудников, которым добавляется ключ
 */
export const addMachineAccessesKeyThunk = createAsyncThunk<
  void,
  { organizationId: number; memberList: AddMachineAccessesKeyDTO[] }
>('addMachineAccessesKey', async ({ organizationId, memberList }) => {
  return await api.organizations.addMachineAccessesKey(organizationId, memberList);
});

/**
 * Изменение сотрудника
 */
export const editEmployeeThunk = createAsyncThunk<
  void,
  { organizationId: number; userUuid: string; data: EditEmployeeDTO }
>('editEmployee', async (data) => {
  return await api.organizations.editEmployee(data);
});

/**
 * Увольнение сотрудника
 */
export const deleteEmployeeThunk = createAsyncThunk<void, DeleteEmployeeDTO>(
  'deleteEmployee',
  async (data) => {
    return await api.organizations.deleteEmployee(data);
  },
);

/**
 * Получение списка файлов организации
 */
export const getOrganizationDocumentLinksThunk = createAsyncThunk<DocumentLinkDTO[], number>(
  'getOrganizationDocumentLinks',
  async (organizationId: number) => {
    return await api.organizations.fetchOrganizationDocs(organizationId);
  },
);

/**
 * Изменение списка файлов организации
 */
export const editFileLinksOrganizationThunk = createAsyncThunk<void, EditFileLinkOrganizationDTO>(
  'editFileLinksOrganization',
  async (data) => {
    return await api.organizations.updateFileLinksOrganization(data);
  },
);

/**
 * Получение списка файлов сотрудника
 */
export const getEmployeeDocumentLinksThunk = createAsyncThunk<
  DocumentLinkDTO[],
  { organizationId: number; userUuid: string }
>('getEmployeeDocumentLinks', async ({ organizationId, userUuid }) => {
  return await api.organizations.fetchEmployeeDocs(organizationId, userUuid);
});

/**
 * Изменение списка файлов сотрудника
 */
export const editFileLinksEmployeeThunk = createAsyncThunk<void, EditFileLinkEmployeeDTO>(
  'editFileLinksEmployee',
  async (data) => {
    return await api.organizations.updateFileLinksEmployee(data);
  },
);

/**
 * Выход из учётной записи
 */
export const logoutThunk = createAsyncThunk<void, void>('logout', async () => {
  return await api.organizations.logout();
});

/**
 * Изменение пароля пользователя
 *
 * @param data данные формы изменения пароля пользователя
 */
export const changePasswordThunk = createAsyncThunk<void, ChangePasswordDTO>(
  'changePassword',
  async (data) => {
    return await api.organizations.changePassword(data);
  },
);
