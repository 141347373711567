import React, { FC, useEffect } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  toggleKeyAction,
  generateKeyAction,
  getKeyAction,
  getEmployeeWithKeyAction,
  getEmployeeWithKeyListAction,
} from '../../../../state/organization/actions';
import { selectMemberKey } from '../../../../state/organization/selectors';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './QrKeyModal.module.scss';
import { QRCode } from 'react-qrcode-logo';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import html2canvas from 'html2canvas';
import { IconPrinterStroked } from '../../../../assets/icon/iconPrinterStroked';
import { IconDownload } from '../../../../assets/icon/iconDownload';
import { useTranslation } from 'react-i18next';

type QrKeyModalProps = {
  isOpen: boolean;
  organizationId: number;
  userUuid: string;
  userName: string;
  isActive: boolean;
  onClose: () => void;
};

const QrKeyModal: FC<QrKeyModalProps> = ({
  isOpen,
  organizationId,
  userUuid,
  userName,
  isActive,
  onClose,
}) => {
  const { t } = useTranslation();

  // Путь к изображению в папке public
  const logoImagePath = process.env.PUBLIC_URL + '/img/qr-logo.png';

  const dispatch = useAppDispatch();

  const key = useAppSelector(selectMemberKey());

  useEffect(() => {
    dispatch(getKeyAction(organizationId, userUuid));
  }, [dispatch, organizationId, userUuid]);

  // Обработчики
  const handleCancelClick = () => {
    dispatch(toggleKeyAction(key, organizationId)).then(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid));
      dispatch(getKeyAction(organizationId, userUuid));
    });
  };

  const handlePrimaryClick = () => {
    dispatch(generateKeyAction(organizationId, userUuid)).then(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid));
      dispatch(getKeyAction(organizationId, userUuid));
      dispatch(getEmployeeWithKeyListAction(organizationId));
    });
  };

  const handleDownload = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const link = document.createElement('a');
      link.download = `${userName}-qr-code.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handlePrint = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const imgData = canvas.toDataURL();

      // Создать новый элемент img с изображением для печати
      const img = new Image();
      img.onload = function () {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.open();
          printWindow.document.write('<img width="200px" src="' + img.src + '" />');

          printWindow.document.write(
            '<p id="key" style="font-size: 18px; color: #21201f; font-weight: 600; margin-left: 45px">key</p>',
          );

          const element = printWindow.document.getElementById('key');
          if (element) {
            element.innerText = key;

            setTimeout(() => {
              console.log('Текст изменен после задержки');
            }, 500);
          }

          printWindow.document.close();

          new Promise<void>(function (resolve) {
            resolve();
          }).then(function () {
            printWindow.print();
          });
        }
      };
      img.src = imgData;
    });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={
          isActive
            ? t('organization.members.QR.key.block.button.label')
            : t('organization.members.QR.key.unblock.button.label')
        }
        view="clear"
        onClick={handleCancelClick}
      />
      <Button
        label={t('organization.members.QR.key.generate.button.label')}
        onClick={handlePrimaryClick}
      />
    </>
  );

  return (
    <DefaultModal
      className={styles.QrKeyModal}
      isOpen={isOpen}
      modalTitle={t('organization.members.QR.key.modal.title')}
      renderActions={renderActions}
      onClose={onClose}
    >
      <div className={styles.card}>
        <VerticalContainer space="2xl" align="center">
          <div className={styles.qrContainer}>
            <QRCode
              value={key}
              qrStyle="fluid"
              logoImage={logoImagePath}
              logoPadding={5}
              logoWidth={53}
              logoHeight={53}
              size={250}
            />
          </div>
          <Text size="l" weight="semibold">
            {key}
          </Text>

          <HorizontalContainer space="2xl" align="center" justify="center">
            <Button
              size="l"
              view="ghost"
              onlyIcon
              iconLeft={IconPrinterStroked as any}
              onClick={handlePrint}
            />
            <Button
              size="l"
              view="ghost"
              onlyIcon
              iconLeft={IconDownload as any}
              onClick={handleDownload}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </div>
    </DefaultModal>
  );
};

export default QrKeyModal;
