import {
  DetailedOrganizationDTO,
  ContactOrganization,
  OrganizationDTO,
} from '../../types/serverInterface/organizationDTO';
import { createSlice } from '@reduxjs/toolkit';
import {
  createInviteEmployeeThunk,
  createOrganizationThunk,
  toggleKeyThunk,
  deleteEmployeeThunk,
  deleteOrganizationThunk,
  editEmployeeThunk,
  editFileLinksEmployeeThunk,
  editFileLinksOrganizationThunk,
  editOrganizationThunk,
  generateKeyThunk,
  getEmployeeDocumentLinksThunk,
  getEmployeesListThunk,
  getEmployeeWithKeyThunk,
  getEmployeeWithKeyListThunk,
  getOrganizationByIdThunk,
  getOrganizationDocumentLinksThunk,
  getOrganizationsListThunk,
  toggleMachineAccessKeyThunk,
  toggleAllMachineAccessKeyThunk,
  addMachineAccessesKeyThunk,
  getMembersListWithoutKeyThunk,
  getKeyThunk,
  logoutThunk,
  getOrganizationContactsByIdThunk,
  editOrganizationContactsThunk,
  changePasswordThunk,
} from './thunk';
import { EmployeeDTO } from '../../types/serverInterface/employeeDTO';
import { baseUrlFront } from '../../consts';
import { errorHandler, NotificationType } from '../handlers';
import { DocumentLinkDTO } from '../../types/serverInterface/documentLink';
import { EmployeeWithKeyDTO } from '../../types/serverInterface/employeeWithKeyDTO';
import { EmployeeWithKeyElementDTO } from '../../types/serverInterface/employeeWithKeyElementDTO';
import { MemberWithoutKeyDTO } from '../../types/serverInterface/memberWithoutKeyDTO';

export type OrganizationState = {
  list: OrganizationDTO[];
  organization: DetailedOrganizationDTO | null;
  loadingOrganization: boolean;
  organizationContacts: ContactOrganization[] | null;
  loadingOrganizationContacts: boolean;
  employees: EmployeeDTO[];
  documentList: DocumentLinkDTO[];
  employeeDocumentList: DocumentLinkDTO[];
  employeeWithKeyElement: EmployeeWithKeyElementDTO | null;
  isLoading: boolean;
  inviteLink: string;
  notifications: NotificationType[];
  employeesWithKey: EmployeeWithKeyDTO[];
  employeesWithoutKey: MemberWithoutKeyDTO[];
  selectedMemberKey: string;
};

const initialState: OrganizationState = {
  list: [],
  organization: null,
  loadingOrganization: false,
  organizationContacts: null,
  loadingOrganizationContacts: false,
  employees: [],
  documentList: [],
  employeeDocumentList: [],
  employeeWithKeyElement: null,
  isLoading: false,
  inviteLink: '',
  notifications: [],
  employeesWithKey: [],
  employeesWithoutKey: [],
  selectedMemberKey: '',
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: OrganizationState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    resetSelectOrganization(state) {
      localStorage.organizationId = null;
      state.organization = null;
    },
  },
  extraReducers: (builder) => {
    // getOrganizationsList
    builder.addCase(getOrganizationsListThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getOrganizationsListThunk.fulfilled, (state, action) => {
      state.list = action.payload || [];
      state.isLoading = false;
    });

    builder.addCase(getOrganizationsListThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getOrganizationById
    builder.addCase(getOrganizationByIdThunk.pending, (state) => {
      state.loadingOrganization = true;
    });

    builder.addCase(getOrganizationByIdThunk.fulfilled, (state, action) => {
      state.loadingOrganization = false;
      state.organization = action.payload;
    });

    builder.addCase(getOrganizationByIdThunk.rejected, (state, action) => {
      state.loadingOrganization = false;
      errorHandler(action)(addNotification(state));
    });

    // getOrganizationContactsByIdThunk
    builder.addCase(getOrganizationContactsByIdThunk.pending, (state) => {
      state.loadingOrganizationContacts = true;
    });

    builder.addCase(getOrganizationContactsByIdThunk.fulfilled, (state, action) => {
      state.loadingOrganizationContacts = false;
      state.organizationContacts = action.payload;
    });

    builder.addCase(getOrganizationContactsByIdThunk.rejected, (state, action) => {
      state.loadingOrganizationContacts = false;
      errorHandler(action)(addNotification(state));
    });

    // createOrganizationThunk
    builder.addCase(createOrganizationThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editOrganizationThunk
    builder.addCase(editOrganizationThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // deleteOrganizationThunk
    builder.addCase(deleteOrganizationThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // createInviteEmployeeThunk
    // TODO: если что, тут поправить ссылку
    builder.addCase(createInviteEmployeeThunk.fulfilled, (state, action) => {
      state.inviteLink =
        action.payload.hash && `${baseUrlFront}/auth/registration/${action.payload.hash}`;
    });

    builder.addCase(createInviteEmployeeThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getEmployeesList
    builder.addCase(getEmployeesListThunk.fulfilled, (state, action) => {
      state.employees = action.payload;
    });

    builder.addCase(getEmployeesListThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getEmployee
    builder.addCase(getEmployeeWithKeyThunk.fulfilled, (state, action) => {
      state.employeeWithKeyElement = action.payload;
    });

    builder.addCase(getEmployeeWithKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editEmployee
    builder.addCase(editEmployeeThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // deleteEmployee
    builder.addCase(deleteEmployeeThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editOrganizationContacts
    builder.addCase(editOrganizationContactsThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getOrganizationDocumentLinks
    builder.addCase(getOrganizationDocumentLinksThunk.fulfilled, (state, action) => {
      state.documentList = action.payload;
    });

    builder.addCase(getOrganizationDocumentLinksThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editFileLinksOrganization
    builder.addCase(editFileLinksOrganizationThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getOrganizationDocumentLinks
    builder.addCase(getEmployeeDocumentLinksThunk.fulfilled, (state, action) => {
      state.employeeDocumentList = action.payload;
    });

    builder.addCase(getEmployeeDocumentLinksThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editFileLinksOrganization
    builder.addCase(editFileLinksEmployeeThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // logoutThunk
    builder.addCase(logoutThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getEmployeeWithKeyList
    builder.addCase(getEmployeeWithKeyListThunk.fulfilled, (state, action) => {
      state.employeesWithKey = action.payload;
    });

    builder.addCase(getEmployeeWithKeyListThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // deactivateKeyThunk
    builder.addCase(toggleKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // generateKeyThunk
    builder.addCase(generateKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // toggleMachineAccessKeyThunk
    builder.addCase(toggleMachineAccessKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // toggleAllMachineAccessKeyThunk
    builder.addCase(toggleAllMachineAccessKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getMembersListWithoutKeyThunk
    builder.addCase(getMembersListWithoutKeyThunk.fulfilled, (state, action) => {
      state.employeesWithoutKey = action.payload;
    });

    builder.addCase(getMembersListWithoutKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // addMachineAccessesKeyThunk
    builder.addCase(addMachineAccessesKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getKeyThunk
    builder.addCase(getKeyThunk.fulfilled, (state, action) => {
      state.selectedMemberKey = action.payload.key;
    });

    builder.addCase(getKeyThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // changePasswordThunk
    builder.addCase(changePasswordThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });
  },
});

export const organizationReducer = organizationSlice.reducer;

export const { resetSelectOrganization } = organizationSlice.actions;
