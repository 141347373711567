import { useExportPromoCodeFromExcel } from './useExportPromoCodeFromExcel';
import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { useExportPromoCodeFromClickBoard } from './useExportPromoCodeFromClickBoard';
import { Pagination } from '../../../../types/types';
import useExportPromoCodeFromPrint from './useExportPromoCodeFromPrint';

export const useExportPromoCode = (filters: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
  t: (key: string) => string;
}) => {
  const { handleExcelPromoCodeExportClick } = useExportPromoCodeFromExcel(filters);
  const { handleExportFromClickBoardClick } = useExportPromoCodeFromClickBoard(filters);
  const { handleExportFromPrint } = useExportPromoCodeFromPrint(filters);

  return {
    handleExcelPromoCodeExportClick,
    handleExportFromClickBoardClick,
    handleExportFromPrint,
  };
};
